import { useEffect, useState } from "react";
import getRandomEmoji from "get-random-emoji";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function randomHSL() {
  return "hsla(" + ~~(360 * Math.random()) + "," + "70%," + "80%,1)";
}

function App() {
  const { height, width } = useWindowDimensions();
  const [rows, setRows] = useState([]);

  useState(() => {
    let theRows = [];
    let theColumns = [];
    const rowFormula = Math.floor(height / 10);
    const colFormula = Math.floor(width / 10);
    for (var i = 0; i < rowFormula; i++) {
      theColumns = [];
      for (var j = 0; j < colFormula; j++) {
        theColumns.push(
          <td className="text-3xl p-2" key={j}>
            {getRandomEmoji()}
          </td>
        );
        if (j === colFormula - 1)
          theRows.push(
            <tr className="" key={i}>
              {theColumns}
            </tr>
          );
      }
    }
    setRows(theRows);
  }, []);
  return (
    <div className="p-2 overflow-hidden" style={{ background: randomHSL() }}>
      <table className="overflow-hidden">{rows}</table>
    </div>
  );
}

export default App;
